import React from 'react'
import { Row, Col } from 'antd'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import NewsItem from './NewsItem'

class indexNews extends React.PureComponent {
  render() {
    const { data } = this.props
    return (
      <div className='news-wrap'>
        <div className='main-con news-block'>
          <h2 className='title-wrapper'>新闻动态</h2>
          <OverPack playScale={0.3}>
            <QueueAnim
              type='bottom'
              key='block'
              leaveReverse
              component={Row}
              componentProps={{
                className: 'content0-block-wrapper',
              }}
            >
              {data.map(item => {
                return (
                  <Col key={item.id} className='news-item-wrap' md={6} xs={24}>
                    <NewsItem newsitem={item} />
                  </Col>
                )
              })}
            </QueueAnim>
          </OverPack>
          <div className='more-wrap'>
            <span className='more-btn'>
              <a href='/news'>更多新闻</a> &gt;{' '}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default indexNews
