import axios from 'axios'
import apis from './apis'

function comHttp(method, url, data) {
  const p = new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      success: res => {
        resolve(res.data)
      },
      fail: err => {
        reject(err)
      },
    })
  })
  return p
}
const request = {}

request.counts = data => comHttp('get', apis.counts, data)
request.tops = data => comHttp('get', apis.tops, data)
request.geos = data => comHttp('get', apis.geos, data)
request.histories = data => comHttp('get', apis.histories, data)

export default request
