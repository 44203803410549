import React from 'react'
import { Row, Col, Carousel } from 'antd'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'

class Partner extends React.Component {
  constructor(props) {
    super(props)
    this.carouselRef = React.createRef()
  }

  goPrev = () => {
    this.carouselRef.current.prev()
  }

  goNext = () => {
    this.carouselRef.current.next()
  }

  chunk = (arr, num) => {
    // eslint-disable-next-line no-param-reassign
    num = num * 1 || 1
    const ret = []
    arr.forEach((item, i) => {
      if (i % num === 0) {
        ret.push([])
      }
      ret[ret.length - 1].push(item)
    })
    return ret
  }

  render() {
    const { data } = this.props
    const partners = this.chunk(data, 12)
    console.log(data)

    return (
      <div className='partner-wrap'>
        <div className='main-con'>
          <h2>合作伙伴</h2>
          <OverPack playScale={0.2}>
            <QueueAnim
              type='bottom'
              key='block'
              leaveReverse
              component={Row}
              componentProps={{
                className: 'content0-block-wrapper',
              }}
            >
              <div className='partner-list'>
                <Carousel ref={this.carouselRef} className='partner-page'>
                  {partners.map((partner, index) => (
                    <div className='page-item' key={index.toString()}>
                      <Row gutter={16} style={{ padding: '10px' }}>
                        {partner.map(item => (
                          <Col span={6} key={item.id}>
                            <div className='logo-item'>
                              <a href={item.link}>
                                <img
                                  className='partner-logo-pic'
                                  src={item.image_link}
                                  alt='PartnerLogo'
                                />
                              </a>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </Carousel>
                <span
                  className='prev-btn'
                  role='button'
                  tabIndex='-1'
                  onClick={this.goPrev}
                  onKeyDown={this.goPrev}
                ></span>
                <span
                  className='next-btn'
                  role='button'
                  tabIndex='-2'
                  onClick={this.goNext}
                  onKeyDown={this.goNext}
                ></span>
              </div>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    )
  }
}
export default Partner
