import React from 'react'
import { navigate } from 'gatsby'

class SolutionItem extends React.PureComponent {
  getUrl = id => {
    let url = ''
    switch (id) {
      case '1':
        url = '/solution-list/tea-pattern-cloud'
        break
      case '2':
        url = '/solution-list/glue-plowing'
        break
      case '4':
        url = '/core-technology/plant-insect-pests-analysis'
        break
      case '5':
        url = '/solution-list/marijuana'
        break
      default:
        url = ''
        break
    }
    return url
  }

  render() {
    const { height, data } = this.props
    const imageUrl = data.image
      ? `${process.env.STRAPI_URL}${data.image.url}`
      : data.image_link
    return (
      <div
        className='solution-item-wrap'
        style={{ height }}
        onClick={() => navigate(this.getUrl(data.id))}
      >
        <img alt='example' src={imageUrl} />
        <div className='text-con'>
          <h3>{data.title}</h3>
          <p>{data.short_description}</p>
        </div>
      </div>
    )
  }
}
export default SolutionItem
