import React from 'react'
import ShuyunImg from './../../images/shuyun.png'

class HeadNav extends React.PureComponent {
  render() {
    return (
      <div className='home-page-wrapper'>
        <div className='main-con shuyun-con'>
          <h2>数耘</h2>
          <p>基于工业互联网架构的农业产业数字化平台</p>
          <img src={ShuyunImg} alt='数云' />
        </div>
      </div>
    )
  }
}
export default HeadNav
