import React from 'react'
import SolutionItem from './SolutionItem'
import { Row, Col } from 'antd'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'

class Solution extends React.PureComponent {
  render() {
    const { data } = this.props
    const headData = data.slice(0, 3)
    const footData = data.slice(3, 6)
    let viewW = 0
    if (typeof document !== 'undefined') {
      viewW = document.querySelector('body').offsetWidth
    }
    return (
      <div className='home-page-wrapper content0-wrapper solution-wrap'>
        <h2>解决方案</h2>
        <OverPack playScale={0.3}>
          <QueueAnim
            type='bottom'
            key='block'
            leaveReverse
            component={Row}
            componentProps={{
              className: 'content0-block-wrapper',
            }}
          >
            <div className='solution-list'>
              <Row>
                {headData.map((item, index) => (
                  <Col span={index === 1 ? 12 : 6} key={item.id}>
                    <SolutionItem data={item} height={(72 * viewW) / 192} />
                  </Col>
                ))}
              </Row>
              <Row>
                {footData.map((item, index) => (
                  <Col span={index === 1 ? 12 : 6} key={item.id}>
                    <SolutionItem data={item} height={(36 * viewW) / 192} />
                  </Col>
                ))}
              </Row>
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    )
  }
}

export default Solution
