import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'

import Banner1 from '../components/antd/Banner1'
import Layout from '../components/layout'
import Shuyun from './../components/antd/Shuyun'

import { Banner11DataSource } from '../components/antd/data.source'
import '../components/antd/less/antMotionStyle.less'
import SEO from '../components/seo'
import IndexNews from '../components/antd/IndexNews'
import Solution from '../components/antd/Solution'
import Partner from '../components/antd/Partner'
import request from './../utils/request'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Home extends React.Component {
  constructor(...props) {
    super(...props)
    this.state = {
      isMobile,
    }
  }

  getCounts = () => {
    request.counts({ type: 'all' })
    // .then(res => {
    //   console.log('counts===', res)
    // })
    request.tops({})
    // .then(res => {
    //   console.log('tops===', res)
    // })
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
    this.getCounts()
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const {
      news = [],
      banners,
      partners,
      solutions,
      products,
      productGroups = [],
    } = strapi

    const newNews = news.reverse()
    const children = [
      <Banner1
        id='Banner1_1'
        key='Banner1_1'
        dataSource={Banner11DataSource}
        data={banners}
        isMobile={this.state.isMobile}
      />,
      <Shuyun key='shuyun' id='shuyun' isMobile={this.state.isMobile} />,
      <Solution
        id='solution'
        key='solution'
        data={solutions}
        isMobile={this.state.isMobile}
      />,
      <IndexNews
        id='indexNews'
        key='indexNews'
        data={newNews.slice(0, 4)}
        isMobile={this.state.isMobile}
      />,
      <Partner
        id='Partner'
        key='Partner'
        data={partners}
        isMobile={this.state.isMobile}
      />,
    ]
    return (
      <Layout
        curIndex={0}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='首页' />
        {children}
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    strapi {
      news(sort: "publish_date") {
        id
        title
        publish_date
        short_description
        link
        tags {
          id
          key
        }
        img {
          url
        }
      }
      banners {
        id
        name
        title
        bg
        content
        more_btn
      }
      partners {
        id
        link
        image {
          url
        }
        image_link
      }
      solutions(sort: "sequence") {
        id
        title
        short_description
        image {
          url
        }
        image_link
        content
        sequence
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
