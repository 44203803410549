const host = 'https://api-dev.chawenyun.com/api-dev3'

// 在每一项前面拼上host
const apiFn = json =>
  Object.keys(json).reduce(
    (result, key) => ({ ...result, [key]: `${host}${json[key]}` }),
    {}
  )

const requsetUrls = {
  counts: '/analytics/counts',
  tops: '/analytics/tops',
  region: '/analytics/counts/region',
  geos: '/analytics/geos',
  histories: '/analytics/histories',
}
const apis = apiFn(requsetUrls)

export default apis
