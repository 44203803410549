import React from 'react'
import { Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import QueueAnim from 'rc-queue-anim'
import { navigate } from 'gatsby'
import TweenOne, { TweenOneGroup } from 'rc-tween-one'
import BannerAnim, { Element } from 'rc-banner-anim'
import { isImg } from './utils'
import 'rc-banner-anim/assets/index.css'

const { BgElement } = Element
class Banner extends React.PureComponent {
  render() {
    const { ...props } = this.props
    const { data } = props
    delete props.dataSource
    delete props.isMobile
    const childrenToRender = data.map((item, i) => {
      const { bg, title, content, id, name, more_btn: moreBtn } = item
      return (
        <Element
          key={i.toString()}
          className='banner-user-elem'
          followParallax={{
            delay: 1000,
            data: [
              {
                id: name,
                value: 20,
                bgPosition: '50%',
                type: ['backgroundPositionX'],
              },
              { id: 'title', value: -20, type: 'x' },
              { id: `queue-${name}`, value: 50, type: 'x' },
              { id: 'JText', value: -30, type: 'x' },
            ],
          }}
        >
          {id === '3' ? (
            <BgElement
              id={id}
              key='bg'
              className='bg'
              style={{ background: `url("${bg}") center`, cursor: 'pointer' }}
              onClick={() => navigate('/recruitment')}
            />
          ) : (
            <BgElement
              id={id}
              key='bg'
              className='bg'
              style={{ background: `url("${bg}") center` }}
            />
          )}

          <QueueAnim
            id={`queue-${name}`}
            type={['bottom', 'top']}
            delay={200}
            key='text'
            className='banner1-text-wrapper'
          >
            {title && (
              <div key='logo' className='banner1-title'>
                {typeof title === 'string' && title.match(isImg) ? (
                  <img src={title} width='100%' alt='img' />
                ) : (
                  title
                )}
              </div>
            )}
            <div key='content' className='banner1-content'>
              {content}
            </div>
            {moreBtn && (
              <Button
                ghost
                key='button'
                className='banner1-button'
                href={moreBtn.link ? moreBtn.link : '#'}
              >
                {moreBtn.title}
              </Button>
            )}
          </QueueAnim>
        </Element>
      )
    })
    return (
      <div {...props} className='banner1'>
        <TweenOneGroup
          key='bannerGroup'
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=''
        >
          <div className='banner1-wrapper' key='wrapper'>
            <BannerAnim type='across' key='BannerAnim' autoPlay>
              {childrenToRender}
            </BannerAnim>
          </div>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className='banner1-icon'
          style={{ bottom: 40 }}
          key='icon'
        >
          <DownOutlined />
        </TweenOne>
      </div>
    )
  }
}

export default Banner
